import {useStore} from '@/utils'
import {computed} from "vue";
import router from "@/router";

export const useYandexMetrika = () =>
{
	const store = useStore();
	const ymInitiialized = computed(() => store.getters.getYmInitiialized);
	const initYandexMetrika = (counterId) => store.dispatch('initYandexMetrika', counterId);

	const yandexMetrika = async () =>
	{
		if (typeof process.env.VUE_APP_YM_COUNTER_ID === "undefined") return;

		await initYandexMetrika(process.env.VUE_APP_YM_COUNTER_ID);

		ym(process.env.VUE_APP_YM_COUNTER_ID, 'hit', `/application${router.currentRoute.fullPath}`);
	};

	const yandexMetrikaGoal = async (counterId, goal, UserID) =>
	{
		if (typeof process.env.VUE_APP_YM_COUNTER_ID === "undefined") return;

		await initYandexMetrika(counterId);

		ym(process.env.VUE_APP_YM_COUNTER_ID, 'reachGoal', goal);

		if (goal === 'log_in' && UserID) ym(process.env.VUE_APP_YM_COUNTER_ID, 'userParams', {UserID});
	};

	// Электронная комерция: Просмотр товара
	const ecommerceDetailProduct = (product) =>
	{
		if (!ymInitiialized.value || !product) return;

		window.dataLayer.push({
			"ecommerce": {
				"currencyCode": "RUB",
				"detail": {
					"products": [
						{
							"id": product.id,
							"name": product.name,
							"price": product.price,
							"brand": product.code,
							"category": product.type?.name,
							"discount": parseFloat(product.discount),
						}
					]
				}
			}
		});
	};

	// Электронная комерция: Добавление товара в корзину
	const ecommerceAddToCart = (product) =>
	{
		if (!ymInitiialized.value || !product) return;

		window.dataLayer.push({
			"ecommerce": {
				"currencyCode": "RUB",
				"add": {
					"products": [
						{
							"id": product.id,
							"name": product.name,
							"price": product.price,
							"brand": product.code,
							"category": product.type?.name,
							"discount": parseFloat(product.discount),
						}
					]
				}
			}
		});
	};

	// Электронная комерция: Удаление товара из корзины
	const ecommerceRemoveFromCart = (product) =>
	{
		if (!ymInitiialized.value || !product) return;

		window.dataLayer.push({
			"ecommerce": {
				"currencyCode": "RUB",
				"remove": {
					"products": [
						{
							"id": product.id,
							"name": product.name,
							"price": product.price,
							"brand": product.code,
							"category": product.type?.name,
							"discount": parseFloat(product.discount),
						}
					]
				}
			}
		});
	};

	// Электронная комерция: Покупка
	const ecommerceBuy = (products, orderId) =>
	{
		if (!ymInitiialized.value || !products) return;

		window.dataLayer.push({
			"ecommerce": {
				"currencyCode": "RUB",
				"purchase": {
					"actionField": {
						"id": orderId
					},
					"products": products.map(product => ({
						"id": product.id,
						"name": product.name,
						"price": product.price,
						"brand": product.code,
						"category": product.type?.name,
						"discount": parseFloat(product.discount),
						"quantity": parseInt(product.quantity)
					}))
				}
			}
		});
	};

	return {
		ymInitiialized,
		initYandexMetrika,
		yandexMetrika,
		yandexMetrikaGoal,
		ecommerceDetailProduct,
		ecommerceAddToCart,
		ecommerceRemoveFromCart,
		ecommerceBuy,
	};
}