<template>
	<div class="meal-popup">
		<div class="meal-popup__left">
			<MealListItemSlider
				class="meal-popup__slider"
				:big="true"
				:imgs="imgs"
				:active="activeSlide"
				@change="activeSlide = $event"
			/>

			<div class="meal-popup__slider-previews">
				<img
					v-for="(img, num) in imgs"
					:key="num"
					width="64"
					height="64"
					class="meal-popup__slider-preview"
					:class="{active: activeSlide === num}"
					@click="activeSlide = num"
					:src="img"
				/>
			</div>
		</div>

		<div class="meal-popup__right">
			<div class="meal-popup__title">
				{{ name }}
			</div>

			<div class="meal-popup__short-nutrition">
				{{ type }} • {{ weight }} гр
			</div>

			<div class="meal-popup__composition-block">
				<div class="meal-popup__subtitle">Состав продукта:</div>
				<div class="meal-popup__composition-text">
					{{ composition }}
				</div>
			</div>

			<div class="meal-popup__nutrition-block">
				<div class="meal-popup__subtitle">Пищевая ценность блюда:</div>
				<div class="meal-popup__nutrition-table">
					<div class="meal-popup__nutrition-table-row">
						<div>Белки</div>
						<div class="meal-popup__dots"/>
						<div>{{ (nutrition.proteins * .01 * weight).toFixed(0) }} гр</div>
					</div>

					<div class="meal-popup__nutrition-table-row">
						<div>Жиры</div>
						<div class="meal-popup__dots"/>
						<div>{{ (nutrition.fats * .01 * weight).toFixed(0) }} гр</div>
					</div>

					<div class="meal-popup__nutrition-table-row">
						<div>Углеводы</div>
						<div class="meal-popup__dots"/>
						<div>{{ (nutrition.carbs * .01 * weight).toFixed(0) }} гр</div>
					</div>

					<div class="meal-popup__nutrition-table-row">
						<div>Энерг. ценность</div>
						<div class="meal-popup__dots"/>
						<div>{{ (nutrition.calories * .01 * weight).toFixed(0) }} Ккал</div>
					</div>
				</div>
			</div>

			<div class="meal-popup__rest">
				<svg
					class="meal-popup__rest-icon"
					xmlns="http://www.w3.org/2000/svg"
					width="28"
					height="28"
					fill="none"
				>
					<path
						fill="#40AE49"
						d="M0 14C0 8.4 0 5.6 1.3 3.6a8 8 0 0 1 2.3-2.3C5.6 0 8.4 0 14 0c5.6 0 8.4 0 10.4 1.3a8 8 0 0 1 2.3 2.3C28 5.6 28 8.4 28 14c0 5.6 0 8.4-1.3 10.4a8 8 0 0 1-2.3 2.3C22.4 28 19.6 28 14 28c-5.6 0-8.4 0-10.4-1.3a8 8 0 0 1-2.3-2.3C0 22.4 0 19.6 0 14Z"
					/>
					<g
						fill="#fff"
						clip-path="url(#a)"
					>
						<path
							d="M11 16a2.4 2.4 0 0 1-.7 0l-4 4c-.3.2-.3.6 0 .8l1 1c.2.2.5.2.7 0l5-5-1-.9h-1ZM21.9 9.4a.5.5 0 0 0-.7 0l-2.7 2.7a.6.6 0 0 1-1-1l2.7-2.6a.5.5 0 0 0-.7-.7L17 10.4a.6.6 0 1 1-1-.9L18.7 7a.5.5 0 1 0-.7-.7l-3 3c-.7.7-1 1.7-.7 2.6l2 2c1 .2 2 0 2.7-.7l3-3v-.7Z"
						/>
						<path
							d="M7.3 6.5a.5.5 0 0 0-.8.1c-.9 1.8-.5 4 .9 5.4l2.5 2.5a1.3 1.3 0 0 0 1 .4l1.6-.1 6.4 7 .4.1.4-.1 1-1.2a.5.5 0 0 0 0-.7L7.4 6.5Z"
						/>
					</g>
					<defs>
						<clipPath id="a">
							<path
								fill="#fff"
								d="M0 0h16v16H0z"
								transform="translate(6 6)"
							/>
						</clipPath>
					</defs>
				</svg>

				<span class="meal-popup__rest-name-block">
					<span class="meal-popup__rest-prename">Продавец:</span>
					<span class="meal-popup__rest-name">{{ rest.name }}</span>
				</span>


				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					class="meal-popup__rest-info"
					@mouseover="showTooltip = true"
					@mouseleave="showTooltip = false"
				>
					<path
						fill-rule="evenodd"
						d="M11 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm0 3a1 1 0 0 1 2 0v5a1 1 0 0 1-2 0v-5Zm1 9a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm0-18a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z"
						clip-rule="evenodd"
					/>
				</svg>

				<Tooltip
					class="meal-popup__rest-tooltip"
					:info="true"
					:list="false"
					:bottomArrow="true"
					v-if="showTooltip"
					@click.native="showTooltip = false"
				/>
			</div>

			<div class="meal-popup__bottom">

				<div
					class="meal-popup__old-price"
					v-if="oldPrice"
				>
					Цена:
					<span class="meal-popup__old-price__crossed">{{ oldPrice }} ₽</span>
				</div>
				<div class="meal-popup__short-nutrition">
					{{ type }} • {{ weight }} гр
				</div>
				<div class="meal-popup__current-price-container">
					<div class="meal-popup__current-price">{{ price }} ₽</div>
					<div
						class="meal-popup__bonus"
						v-if="bonus && currentBasket?.owner"
					>
						+{{ bonus }}
						<img :src="require('@/assets/img/icons/bonus.svg')"/>
					</div>
				</div>

				<div
					class="meal-popup__in-app-price"
					v-if="inAppPrice"
				>В приложении: {{ inAppPrice }}₽
				</div>

			</div>

			<div class="meal-popup__actions">
				<UiButton
					size="medium"
					@click="changeCartItem(1)"
					v-if="!count"
					:loading="loading"
				>
					В корзину
				</UiButton>
				<Counter
					v-else
					class="meal-mobile-popup__add-to-cart"
					:big="true"
					:checkCounterBetweenChange="checkCartBeforeAddProduct"
					:value="count"
					@change="changeCartItem"
				/>
			</div>

		</div>
	</div>
</template>

<script>
import MealListItemSlider from './MealListItemSlider.vue'
import Tooltip from '../Tooltip.vue';
import Counter from '@/components/ui/Counter.vue';
import {useChangeCartItem, useYandexMetrika} from "@/composables";
import {mapGetters} from "vuex";
import {UiButton} from "ui-kit";

export default {
	name: 'MealPopup',
	setup()
	{
		const {ecommerceAddToCart, ecommerceRemoveFromCart, ecommerceDetailProduct} = useYandexMetrika();
		const {checkCartBeforeAddProduct, changeCartItem, initProduct, count, loading} = useChangeCartItem();

		return {
			ecommerceAddToCart,
			ecommerceRemoveFromCart,
			ecommerceDetailProduct,
			checkCartBeforeAddProduct,
			changeCartItem,
			initProduct,
			count,
			loading
		};
	},
	computed: {
		...mapGetters({
			currentBasket: "getCurrentBasket",
		}),
	},
	components: {
		UiButton,
		MealListItemSlider,
		Tooltip,
		Counter
	},
	props: {
		item: Object,
		id: Number,
		imgs: Array,
		type: String,
		nutrition: Object,
		rating: Number,
		name: String,
		badges: Array,
		composition: String,
		weight: Number,
		discount: Number,
		price: Number,
		oldPrice: Number,
		inAppPrice: Number,
		bonus: Number,
		rest: Object,
	},
	data: () => ({
		activeSlide: 0,
		showTooltip: false,
	}),
	mounted()
	{
		this.initProduct(this.item);
		// Электронная комерция: Просмотр товара
		if (this.item) this.ecommerceDetailProduct(this.item);
	}
}
</script>

<style lang="scss">
.meal-popup
{
	display: grid;
	grid-template-columns: 600px;
	grid-template-columns: 516px 308px;
	column-gap: 56px;

	&__right
	{
		color: $greyLightText;
	}

	&__left
	{
		padding-bottom: 20px;
	}

	&__slider
	{
		height: 100%;
		width: 100%;
	}

	&__slider-previews
	{

		position: absolute;
		display: flex;
		flex-direction: column;
		top: 40px;
		left: 40px;
		z-index: 2;
	}

	&__slider-preview
	{

		width: 64px;
		height: 64px;
		object-fit: contain;
		margin-bottom: 8px;
		border: 2px solid $greyDelimiter;
		border-radius: 8px;
		opacity: .7;
		cursor: pointer;
		transition: .3s all ease;

		&.active
		{
			border-color: $green;
			opacity: 1;
		}
	}

	&__title
	{
		font-size: 28px;
		font-weight: bold;
		font-family: $mainFontBoldNew;
		margin-bottom: 16px;
		line-height: 32px;
		color: $textColor;
	}

	&__short-nutrition
	{
		margin-bottom: 16px;
		font-size: 14px;
	}

	&__subtitle
	{
		color: $textColor;
		font-weight: bold;
		font-family: $mainFontBoldNew;
		font-size: 16px;
		margin-bottom: 8px;
	}

	&__composition
	{

		border-right: 4px $greyLightText solid;

		&-block
		{
			margin-bottom: 20px;
		}

		&-text
		{
			font-size: 14px;
		}

	}

	&__nutrition
	{

		&-block
		{
			margin-bottom: 16px;
		}

		&-table
		{

			font-size: 14px;

			&-row
			{
				display: flex;

				*
				{
					flex-shrink: 0;
				}
			}

		}

	}

	&__dots
	{
		width: 100%;
		border-bottom: dotted 1px $greyLightText;
		flex-shrink: 1;
		margin-bottom: 6px;
		margin-left: 6px;
		margin-right: 6px;
	}

	&__rest
	{
		display: flex;
		align-items: center;
		padding: 4px 8px;
		border-radius: 8px;
		width: 100%;
		background: $lightGreenBackground;
		font-size: 14px;
		position: relative;

		&-tooltip
		{
			right: 0;
			bottom: 49px;
		}

		&-icon
		{
			min-width: 28px;
			margin-right: 8px;
		}

		&-prename
		{
			margin-right: 8px;
		}

		&-name
		{

			&-block
			{
				width: 100%;
			}

			color: $textColor;
			font-weight: 500;
			font-family: $mainFontMediumNew;
		}

		&-info
		{
			min-width: 24px;
			cursor: pointer;
			fill: #979797;
			border-radius: 24px;
			transition: all .3s ease;
			z-index: 1;

			&:hover
			{
				background: #333;
				fill: #fff;
			}
		}
	}

	&__bottom
	{
		margin: 16px 0 10px 0;
	}

	&__old-price
	{
		font-size: 12px;
		color: $textColor;
		grid-area: old-price;

		&__crossed
		{
			font-family: $mainFontBoldNew;
			color: $greyLightText;
			text-decoration-line: line-through;
		}
	}

	&__current-price-container
	{
		display: flex;
		grid-area: price;
	}

	&__current-price
	{
		margin-right: 10px;
		font-size: 32px;
		font-family: $mainFontBoldNew;
		letter-spacing: -1px;
		color: $textColor;
	}

	&__bonus
	{
		padding: 0 7px 0 4px;
		border-radius: 4px;
		background: $green;
		font-family: $mainFontBoldNew;
		font-size: 14px;
		color: #fff;

		display: flex;
		align-items: center;

		img
		{
			margin-left: 3px;
			margin-top: -2px;
		}
	}

	&__in-app-price
	{
		grid-area: in-app-price;
		min-width: 0;
		border-radius: 4px;
		font-size: 11px;
		line-height: 11px;
		color: $green;
		padding: 2px 4px;
		font-family: $mainFontBoldNew;
		background: $lightGreenBackground;
		justify-self: start;
	}

	&__add-to-cart
	{
		width: 110px;
		height: 40px;
		font-size: 16px;
		grid-area: button;
		justify-self: end;
	}

	&__actions
	{

		.btn
		{
			width: 100%;
			height: 56px;
		}
	}

}
</style>
