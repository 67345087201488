<template>
	<div class="ui-switch-line">
		<!-- Прямоугольник, указывающий на выбранный элемент -->
		<div
			class="ui-switch-line__square"
			:style="{
        		width: squareWidth,
        		transform: `translateX(${squarePosition})`
      		}"
		/>
		<!-- Контейнер элементов в переключателе -->
		<div class="ui-switch-line__container">
			<div
				v-for="item in items"
				class="ui-switch-line__item"
				:class="{selected: item.value === selectedItem, disabled: item.disabled}"
				@click="changeActiveItem(item)"
				:key="item.value"
			>
				{{ item.name }}
				<span v-if="item.subtitle">{{ item.subtitle }}</span>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'SwitchLine',
	props:
		{
			items: Array,                               // Элементы в переключателе
			selectedItem: {type: String, default: null}, // Значение текущего активного эелемента
			canSwitch: {
				type: Boolean,
				default: true
			}, // Параментр, который отвечает за то, можно ли менять активный элемент или нет
			// По умолчанию всегда true
		},

	model: {
		prop: 'selectedItem',
		event: 'itemChanged'
	},

	data: () => ({
		// Индекс активного элемента, используется для позиционирования прямоугольника
		selectedItemIndex: 0
	}),

	methods:
		{
			changeActiveItem(item)
			{
				if (!this.canSwitch) return;

				if (item.value == this.selectedItem || item.disabled) return;
				this.$emit('itemChanged', item.value);
			}
		},

	computed: {
		// Ширина прямоугольника
		squareWidth()
		{
			return `calc(${100 / this.items.length}% - 2px)`;
		},

		// Смещение прямоугольника по Х
		squarePosition()
		{
			return `${this.selectedItemIndex * 100}%`
		}
	},

	mounted()
	{
		// Если активный элемент не установлен, активным становится первый элемент
		if (this.selectedItem === null)
		{
			this.$emit('itemChanged', this.items[0]);
		}
		// Иначе ищем индекс активного элемента для перемещения прямоугольника
		else
		{
			this.selectedItemIndex = this.items.findIndex(item => item.value === this.selectedItem);
		}

	},

	watch: {
		// Отслеживание изменения активного элемента
		selectedItem(val)
		{
			this.selectedItemIndex = this.items.findIndex(item => item.value === val);

			if (this.selectedItemIndex === -1)
			{
				console.warn('[components/ui/Switch] Значение', val, 'отсутствует в списке элементов', this.items);
			}
		},
		items(newItems)
		{
			this.selectedItemIndex = newItems.findIndex(item => item.value === this.selectedItem);

			if (this.selectedItemIndex === -1)
			{
				console.warn('[components/ui/Switch] Значение', this.selectedItem, 'отсутствует в списке элементов', this.items);
			}
		}
	}
}
</script>

<style lang="scss">
.ui-switch-line
{
	position: relative;
	height: 44px;
	padding: 3px;
	background: $greyBackground;
	border: 1px solid #F0F0F0;
	border-radius: 8px;
	overflow: hidden;

	&__container
	{
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: 1fr;
		height: 100%;
	}

	&__item
	{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		font: 14px $mainFontBoldNew;
		font-weight: bold;
		cursor: pointer;
		z-index: 1;
		transition: color .3s ease-in-out;

		&.selected
		{
			color: $green;
		}

		&.disabled
		{
			color: $greyLightText;
		}

		span
		{
			font: 12px $mainFontNew;
			font-weight: normal;
			color: $greyLightText;
		}
	}

	&__square
	{
		position: absolute;
		width: 50%;
		left: 2px;
		top: 2px;
		height: calc(100% - 4px);
		background: #fff;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
		border-radius: 8px;
		transition: transform .3s ease-in-out;
	}
}
</style>