<!-- Компонент списка блюд --->
<template>
	<div class="meal-list">
		<MealListItem
			v-for="item in items"
			:key="item.id"
			:item = "item"
			:id="parseInt(item.id)"
			:imgs="getImgs(item.preview_picture, item.detail_picture)"
			:type="item.type"
			:nutrition="{
				calories: item.kkal,
				proteins: item.belki,
				fats: item.jiri,
				carbs: item.uglevodi
			}"
			:rating="5"
			:name="item.name"
			:badges="[]"
			:composition="item.sostav"
			:discount="parseInt(item.discount)"
			:price="item.price"
			:oldPrice="item.price_old"
			:inAppPrice="null"
			:weight="parseInt(item.weight)"
			:bonus="parseInt(item.balls)"
			:imgBackground="categoryBackground"
			@selected="$emit('selected', item)"
		/>
	</div>
</template>

<script>
import MealListItem from './MealListItem.vue'

export default {
	components: {MealListItem},
	name: 'MealList',
	props:
		{
			items: Array,
			categoryBackground:
				{
					type: String,
					default: '#fff'
				}
		},
	methods:
		{
			getImgs(preview, detail)
			{
				const imgs = [];

				if (preview?.thumb)
					imgs.push(preview.thumb)

				if (detail?.thumb)
					imgs.push(detail.thumb)

				return imgs;
			}
		},
	computed: {
		item()
		{
			return this.items[0]
		}
	}
}
</script>

<style lang="scss">
.meal-list
{
	display: flex;
	flex-wrap: wrap;
	gap: 32px;

	@media (max-width: 567px)
	{
		flex-wrap: nowrap;
		max-width: 100%;
		flex-direction: column;
	}
}
</style>
