<template>
	<div
		class="delivery-rest-wr"
		:class="{'delivery-rest-wr--active': showMobileRest}"
	>
		<div class="delivery">
			<transition name="popup-fade">
				<div class="delivery-rest-state">
					<div class="delivery-rest__title">Выберите ресторан</div>
					<div class="delivery-rest-rows">
						<div
							class="delivery-rest__row"
							v-for="(restaurant, index) in restaurants"
							:key="restaurant.id"
							:class="(currentRestaurantPicked.id === restaurant.id) && 'delivery-rest__row_active'"
							@click="pickActiveRestaurant(restaurant)"
						>
							<div class="delivery-rest__header">
								<div
									class="delivery-rest__header-mobile-row"
									@click="toggleRestaurantInfo(index)"
								>
									<div class="delivery-rest__checked">
										<div
											class="delivery-rest__checkbox"
											:class="{'delivery-rest__checkbox--checked': currentRestaurantPicked.id === restaurant.id}"
											@click="pickActiveRestaurant(restaurant)"
										>
										</div>
									</div>
									<div class="delivery-rest__title-name">{{ restaurant.name }}</div>
									<img
										class="delivery-rest__arrow mobile"
										:class="{'up': moreInfo[index]}"
										src="~@/assets/img/arrowBlack.svg"
									>
								</div>
								<div
									class="delivery-rest__docs-block"
									:class="{'mobile': moreInfo[index]}"
								>
									<span class="delivery-rest__docs">ОГРН: <span>{{ restaurant.ogrn }}</span></span>
									<span class="delivery-rest__docs">ИНН: <span>{{ restaurant.inn }}</span></span>
								</div>
								<img
									class="delivery-rest__arrow"
									:class="{'up': moreInfo[index]}"
									src="~@/assets/img/arrowBlack.svg"
									@click="toggleRestaurantInfo(index)"
								>
							</div>
							<div
								class="delivery-rest__body"
								v-if="moreInfo[index]"
							>
								<span class="delivery-rest__address">{{ restaurant.address }}</span>
								<div
									class="delivery-rest__body-row"
									v-if="restaurant.worktime[0] || restaurant.detail_text"
								>
									<div
										class="delivery-rest__col"
										v-if="restaurant.worktime[0]"
									>
										<div class="delivery-rest__col-name">Режим работы:</div>
										<p class="delivery-rest__body-content">пн-пт: {{ restaurant.worktime[0] }}</p>
										<p class="delivery-rest__body-content">
											сб: {{
												restaurant.works_on_weekends["sat"] ? restaurant.worktime[0] : "выходной"
											}}
											<br>
											вс: {{
												restaurant.works_on_weekends["sun"] ? restaurant.worktime[0] : "выходной"
											}}
										</p>
									</div>
									<div
										class="delivery-rest__col"
										v-if="restaurant.worktime[0]"
									>
										<div class="delivery-rest__col-name">Доставка:</div>
										<p class="delivery-rest__body-content">{{ restaurant.worktime[0] }}</p>
									</div>
									<div
										class="delivery-rest__col"
										v-if="restaurant.detail_text"
									>
										<div class="delivery-rest__col-name">Описание:</div>
										<p class="delivery-rest__body-content">{{ restaurant.detail_text }}</p>
									</div>
								</div>
								<div
									class="delivery-rest__carousel"
									v-if="!!restaurant.images[0]"
								>
									<carousel
										:perPage="1"
										:perPageCustom="[[768, 3]]"
										:paginationEnabled="true"
									>
										<slide
											v-for="(image, index) in restaurant.images"
											:key="index"
											class="delivery-rest__slide"
										>
											<img
												:src="image"
												alt=""
												class="delivery-rest__img-carousel"
											>
										</slide>
									</carousel>
								</div>
							</div>
						</div>
					</div>
					<button
						class="btn button_transparent-red delivery-rest__btn"
						@click="choseResaraunt()"
					>Подтвердить
					</button>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
import {Carousel, Slide} from "vue-carousel";
import animations from "@/mixins/animations.js";
import {mapGetters} from "vuex";

export default {
	components: {Carousel, Slide},
	mixins: [animations],
	props: {
		restaurants: {
			type: Array,
			default: () => []
		},
		showMobileRest:
			{
				type: Boolean,
				default: false
			}
	},
	data()
	{
		return {
			currentRestaurantPicked: false,
			moreInfo: {},
		};
	},
	computed:
		{
			...mapGetters({
				curRest: "order/currentRestaurant",
			}),
		},
	methods:
		{
			/**
			 * Выбор ресторана для показа меню
			 * отправляет emit
			 */
			choseResaraunt()
			{
				this.$emit("selectRestaurant", this.currentRestaurantPicked);
			},

			pickActiveRestaurant(restaurant)
			{
				this.currentRestaurantPicked = restaurant;
			},
			toggleRestaurantInfo(index)
			{
				let entries = Object.entries(this.moreInfo);
				for (let [i, state] of entries)
					if (i != index)
						this.$set(this.moreInfo, i, false);
				this.$set(this.moreInfo, index, !this.moreInfo[index]);
			},
		},
	mounted()
	{
		!!process.env.VUE_APP_MOBILE && appHelper.appPullDownTrigger(false);
		if (this.curRest)
			this.currentRestaurantPicked = this.curRest;
	},
	destroyed()
	{
		!!process.env.VUE_APP_MOBILE && appHelper.appPullDownTrigger(true);
	},
};
</script>
<style lang="scss">
.delivery-rest-wr
{
	font-family: $mainFontNew;

	&--active
	{height: 100vh;}
}

.delivery-rest
{

	&-state
	{padding: 32px;}

	&__btn
	{height: 50px;}

	&__title
	{
		font-family: $mainFontBoldNew;
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 24px;
	}

	&__header
	{
		display: flex;
		align-items: center;
		cursor: pointer;
		width: 100%;
	}

	&__header-mobile-row
	{
		display: flex;
		align-items: center;
	}

	&__title-name
	{
		font-family: $mainFontBoldNew;
		font-weight: bold;
		font-size: 18px;
		margin-right: 10px;
	}

	&__images
	{
		display: flex;

		&-scroll
		{
			overflow-x: scroll;
			white-space: nowrap;
			max-width: 600px;
		}
	}

	&__image
	{
		border-radius: 12px;
		height: 100%;
		width: 100%;

		&-wrapper
		{
			overflow: hidden;
			flex-shrink: 0;
			border-radius: 12px;

			&:not(:last-child)
			{
				margin-right: 20px;
			}
		}
	}

	&__scroll
	{overflow-x: scroll;}

	&__col
	{
		flex: 1 1 33%;
		max-width: 33%;
		font-size: 14px;
		max-height: 177px;

		&-name
		{
			font-family: $mainFontBoldNew;
			font-weight: bold;
		}
	}

	&__slide
	{
		position: relative;
		max-width: 100%;
		opacity: 1;
		overflow: hidden;

		&:not(:last-child)
		{padding-right: 16px;}
	}

	&__img-carousel
	{
		height: 100%;
		width: 100%;
		border-radius: 12px;
	}

	&__body
	{
		margin-top: 20px;
		padding: 0px 40px 0 40px;
		width: 100%;

		&-row
		{
			display: flex;

			&:not(:last-child)
			{margin-bottom: 16px;}
		}

		& .VueCarousel
		{
			max-width: 508px;

			&-wrapper
			{
				width: 103%;
				padding: 0;
			}

			&-slide
			{opacity: 1;}

			&-navigation
			{
				&--disabled
				{opacity: 1;}

				&-button
				{
					top: calc(50% - 15px);
					padding: 0 !important;

					&:focus
					{outline: none;}
				}

				&-prev
				{transform: translateY(-50%) translateX(-25%);}

				&-next
				{transform: translateY(-50%) translateX(25%);}
			}

			&-pagination
			{
				@media (min-width: 768px)
				{display: none;}
			}

			&-dot
			{
				padding: 0 !important;
				height: 10px;
				width: 10px;
				background: #E5E5E7 !important;
				border-radius: 50%;
				margin-top: 0 !important;

				&:focus
				{outline: none;}

				&--active
				{
					background: $green !important;
					border-color: $green;
				}

				&:not(:last-child)
				{margin-right: 8px;}
			}
		}
	}

	&__address
	{
		display: block;
		color: $green;
		font-size: 16px;

		&:not(:last-child)
		{margin-bottom: 12px;}
	}

	&__checkbox
	{
		height: 24px;
		width: 24px;
		border-radius: 50%;
		border: 1px solid $greyLightText;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 16px;
		position: relative;
		cursor: pointer;

		&:before
		{
			content: '';
			position: absolute;
			height: 14px;
			width: 14px;
			border-radius: 50%;
			justify-content: center;
			align-items: center;
		}

		&--checked
		{
			border: 2px solid $red;

			&:before
			{background: $red;}
		}
	}

	&__docs-block
	{display: flex;}

	&__docs
	{
		font-size: 14px;
		line-height: 16px;
		color: $greyLightText;

		&:not(:last-child)
		{margin-right: 10px;}
	}

	&__image
	{
		max-height: 100%;

		&-wrapper
		{width: 180px;}
	}

	&__row
	{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 16px;
		margin-bottom: 16px;
		// margin: 0 32px 16px;
		border: 1px solid #e5e5e7;
		border-radius: 8px;
	}

	&__arrow
	{
		transform: rotate(90deg);
		width: 30px;
		height: 30px;
		margin-left: auto;

		&.mobile
		{display: none;}

		&.up
		{transform: rotate(-90deg);}
	}

	&__btn
	{
		font-family: $mainFontBoldNew;
		font-weight: bold;
		border-radius: 8px;
		width: 100%;
		max-width: 100%;
	}
}

@media (max-width: 1200px)
{
}

@media (max-width: 767px)
{
	.delivery-rest
	{

		&__header
		{
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
		}

		&__header-mobile-row
		{width: 100%;}

		&__arrow
		{
			display: none;

			&.mobile
			{
				display: block;
				width: 25px;
				height: 25px;
			}
		}

		&__checkbox
		{
			height: 20px;
			width: 20px;

			&:before
			{
				height: 10px;
				width: 10px;
			}
		}

		&__title-name
		{
			font-size: 14px;
			line-height: 18px;
		}

		&__docs-block
		{
			margin-top: 16px;
			display: none;

			&.mobile
			{display: flex;}
		}

		&__docs
		{
			font-size: 12px;
			line-height: 18px;
		}

		&__address
		{
			display: block;
			color: $green;
			font-size: 16px;
			line-height: 140%;

			&:not(:last-child)
			{margin-bottom: 12px;}
		}

		&__col
		{
			max-width: 100%;
			font-size: 14px;
			max-height: 177px;

			&:not(:last-child)
			{margin-bottom: 20px;}

			&-name
			{
				font-family: $mainFontBoldNew;
				font-weight: bold;
				margin-bottom: 8px;
			}
		}

		&__body
		{
			margin-top: 12px;
			padding: 0;
			max-width: 100%;

			&-row
			{
				display: flex;
				flex-direction: column;
				max-width: 100%;
			}

		}
	}
}

@media (max-width: 567px)
{
	.delivery-rest
	{
		&__btn
		{margin: 0 auto;}
	}
}
</style>
