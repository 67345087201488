<template>
	<div
		class="restaurant-info"
		@click="restChange"
	>
		<div class="restaurant-info__icon">
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0)">
					<path
						d="M5.06993 9.98385C5.00194 9.98957 4.93412 9.99251 4.86614 9.99251C4.68016 9.99251 4.49928 9.96552 4.32215 9.92452L0.223632 14.0232C0.0224612 14.224 0.0224612 14.5503 0.223632 14.7512L1.28437 15.8122C1.48554 16.0131 1.81147 16.0131 2.01209 15.8122L6.93796 10.8873L6.02508 9.90234L5.06993 9.98385Z"
						fill="white"
					/>
					<path
						d="M15.8552 3.41779C15.6629 3.22554 15.3516 3.22554 15.1593 3.41779L12.5032 6.07375C12.2519 6.3248 11.8448 6.3248 11.5935 6.07375C11.3433 5.82289 11.3433 5.41614 11.5935 5.16535L14.2364 2.52301C14.4361 2.32315 14.4361 1.99914 14.2364 1.79882C14.0358 1.59922 13.7119 1.59922 13.5123 1.79882L10.8704 4.4409C10.6191 4.69221 10.2125 4.69221 9.96119 4.4409C9.71039 4.19013 9.71039 3.78309 9.96119 3.53252L12.6167 0.876828C12.8095 0.684281 12.8095 0.372398 12.6167 0.179591C12.4243 -0.0129885 12.1125 -0.0129885 11.9196 0.179591L8.93181 3.16752C8.21347 3.88632 7.98656 4.89528 8.21912 5.81335L10.222 7.81637C11.14 8.04809 12.1495 7.82107 12.867 7.10286L15.8552 4.1151C16.0481 3.92255 16.0481 3.61034 15.8552 3.41779Z"
						fill="white"
					/>
					<path
						d="M1.34242 0.45154C1.24004 0.349125 1.10159 0.292969 0.959418 0.292969C0.929167 0.292969 0.898753 0.29555 0.869058 0.300515C0.69608 0.329786 0.547668 0.440825 0.47142 0.598874C-0.40376 2.40781 -0.0380073 4.57234 1.38267 5.99308L3.92428 8.53437C4.17505 8.78543 4.51411 8.9244 4.86575 8.9244C4.90335 8.9244 4.94105 8.92283 4.97865 8.91972L6.45431 8.79379L12.889 15.7354C12.9889 15.8433 13.1288 15.906 13.2763 15.9089C13.2793 15.9089 13.2825 15.9089 13.2857 15.9089C13.4298 15.9089 13.5678 15.8519 13.6702 15.7503L14.7722 14.648C14.8741 14.5464 14.931 14.4085 14.931 14.2648C14.931 14.1212 14.8741 13.9832 14.7727 13.8816L1.34242 0.45154Z"
						fill="white"
					/>
				</g>
				<defs>
					<clipPath id="clip0">
						<rect
							width="16"
							height="16"
							fill="white"
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
		<div class="restaurant-info__content">
			<div class="restaurant-info__info">
				<span class="restaurant-info__info-title">Вас обслуживает:</span>
				<span class="restaurant-info__info-name">{{ name }}</span>
				<span class="restaurant-info__info-ogrn">ОГРН {{ ogrn }}</span>
				<span class="restaurant-info__info-inn">ИНН {{ inn }}</span>
			</div>
			<div class="restaurant-info__change">
				<span>Изменить</span>
				<svg
					width="10"
					height="7"
					viewBox="0 0 10 7"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M4.99976 6.50025C4.74376 6.50025 4.48776 6.40225 4.29276 6.20725L0.292762 2.20725C-0.0982383 1.81625 -0.0982383 1.18425 0.292762 0.79325C0.683762 0.40225 1.31576 0.40225 1.70676 0.79325L5.01176 4.09825L8.30476 0.91825C8.70376 0.53525 9.33476 0.54625 9.71876 0.94325C10.1028 1.34025 10.0918 1.97425 9.69476 2.35725L5.69476 6.21925C5.49976 6.40725 5.24976 6.50025 4.99976 6.50025Z"
					/>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {notification} from "@/utils";

export default {
	name: "RestaurantInfo",
	props: {
		name: String,
		ogrn: String,
		inn: String,
	},
	computed:
		{
			...mapGetters({
				currentBasket: "getCurrentBasket",
			})
		},
	methods:
		{
			restChange()
			{
				if (!this.currentBasket?.owner) return;

				if (this.currentBasket?.collective)
				{
					const title = 'Нельзя изменять параметры коллективной корзины после ее создания';
					return notification({title});
				}

				const windowWidth = window.innerWidth;
				this.$emit('change', windowWidth);
			}
		},
};
</script>

<style lang="scss">

.restaurant-info
{
	min-height: 32px;
	padding: 4px;
	padding-left: 8px;
	padding-right: 12px;
	background: #F0F8F0;
	border-radius: 8px;
	display: flex;
	align-items: center;
	font-size: 14px;
	cursor: pointer;

	&__icon
	{
		min-width: 28px;
		height: 28px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $green;
		margin-right: 12px;
	}

	&__content
	{
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__info
	{

		&-title
		{
			color: $greyLightText;
			margin-right: 12px;
		}

		&-name
		{
			font-family: $mainFontBoldNew;
			font-weight: 500;
		}

		&-name, &-ogrn
		{margin-right: 12px;}
	}

	&__change
	{
		font-family: $mainFontBoldNew;
		color: $green;
		cursor: pointer;
		display: flex;
		align-items: center;

		svg
		{
			fill: $green;
			margin-left: 12px;
		}
	}

	@media (max-width: 1439px)
	{

		&__info
		{
			&-inn, &-ogrn
			{display: none;}
		}

	}

	@media (max-width: 567px)
	{

		min-height: 40px;

		&__change
		{
			span
			{display: none;}
		}

		&__info
		{
			line-height: 16px;

			&-name
			{display: block;}
		}

	}
}
</style>
