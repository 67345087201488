<template>
	<div
		class="place-order"
		v-click-outside="closePopup"
	>
		<div
			class="place-order__main-basket"
			v-if="!currentBasket?.collective"
		>
			<div
				class="place-order__main-menu"
				v-if="isMainPage"
			>
				<h3>Оформить заказ из основного меню?</h3>
				<p>Все ранее добавленные блюда будут удалены из корзины</p>
				<div class="place-order__btns">
					<button @click="closePopup">Отмена</button>
					<button @click="addToCart">Продолжить</button>
				</div>
			</div>
			<div
				class="place-order__breakfast"
				v-else
			>
				<h3>Оформить заказ из меню {{ isMealPage }}?</h3>
				<p>Все ранее добавленные блюда будут удалены из корзины</p>
				<div class="place-order__btns">
					<button @click="closePopup">Отмена</button>
					<button @click="addToCart">Продолжить</button>
				</div>
			</div>
		</div>
		<div v-else>
			<div
				class="place-order__main-menu"
				v-if="isMainPage"
			>
				<h3>Нельзя оформить заказ из основного меню</h3>
				<p>Корзина {{ currentBasket.name }} запущена для заказа блюд из другой категории</p>
			</div>
			<div
				class="place-order__breakfast"
				v-else
			>
				<h3>Нельзя оформить заказ из меню {{ isMealPage }}</h3>
				<p>Корзина {{ currentBasket.name }} запущена для заказа блюд из категории обедов</p>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "PlaceOrder",
	data()
	{
		return {};
	},
	props: {
		isMealPage: {
			type: String,
			default: ''
		},
		isMainPage: {
			type: Boolean,
			default: false
		}
	},
	computed:
		{
			...mapGetters({
				currentBasket: 'getCurrentBasket'
			}),
		},
	methods:
		{
			closePopup()
			{
				this.$emit('closePopup');
			},
			async addToCart()
			{
				await this.$store.dispatch('addToCart', {id: this.$store.state.cart.currentIdItem, count: 1});
				this.$emit('closePopup');
			}
		}
};
</script>

<style lang="scss">
.place-order
{
	padding: 32px 40px;
	text-align: center;
	max-width: 462px;
	color: #3D4248;

	h3
	{
		margin-bottom: 8px;
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
		letter-spacing: -0.5px;
	}

	p
	{
		margin-bottom: 24px;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}
}

.place-order__btns
{
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;

	button
	{
		padding: 18px 24px;
		flex: 1 0 50%;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		text-align: center;
		border-radius: 8px;
		border: none;
		cursor: pointer;
		transition: all 0.3s ease;

		&:hover
		{
			opacity: 0.8;
		}
	}

	button:first-child
	{
		color: #3D4248;
		background: #E5E5E7;
	}

	button:last-child
	{
		color: #FFFFFF;
		background: #40AE49;
	}
}
</style>
