import {computed, ref} from "vue";
import {useStore, notification} from "@/utils";
import router from "@/router";

/**
 * Хук для завершения сборки корзины
 *
 * @example
 * const {toggleInitiatorFinished} = useUserCompleteTheBuild();
 *
 * async handleInitiatorFinished(status)
 * {
 * 	 await this.toggleInitiatorFinished(status);
 * }
 *
 * @returns {Object} Объект, содержащий следующие функции и значения:
 * - showUserFinishedPopup: состояние показа всплывающего окна для завершения сборки
 * - toggleUserFinishedPopup: функция для переключения показа всплывающего окна
 * - toggleInitiatorFinished: функция для завершения инициатора сборки
 * - handleUserFinished: асинхронная функция для обработки завершения пользователя
 */
export const useUserCompleteTheBuild = () =>
{
	const store = useStore();
	const showUserFinishedPopup = ref(false);
	const cartId = computed(() => store.getters.getCartId);
	const currentBasket = computed(() => store.getters.getCurrentBasket);
	const setDone = (isDone) => store.commit('setDone', isDone);
	const userFinished = ({cartId, cartTypeId}) => store.dispatch('userFinished', {cartId, cartTypeId});
	const initiatorFinished = ({status, cartTypeId}) => store.dispatch('initiatorFinished', {status, cartTypeId});

	const toggleUserFinishedPopup = () =>
	{
		showUserFinishedPopup.value = !showUserFinishedPopup.value;
	}

	const handleUserFinished = async () =>
	{
		try
		{
			const {data} = await userFinished({
				cartId: cartId.value,
				cartTypeId: currentBasket.value?.id
			});

			if (!data.success)
			{
				const title = 'Что-то пошло не так';
				return notification({title});
			}

			setDone(true);
			await router.push('/finished_build');
		} catch (e)
		{
			throw new Error(e);
		} finally
		{
			toggleUserFinishedPopup();
		}
	}

	const toggleInitiatorFinished = async (status) =>
	{
		try
		{
			await initiatorFinished({
				status,
				cartTypeId: currentBasket.value?.id
			});
		} catch (e)
		{
			throw new Error(e);
		}
	}

	return {
		showUserFinishedPopup,
		toggleUserFinishedPopup,
		toggleInitiatorFinished,
		handleUserFinished
	}
}