<template>
	<div
		class="meal-mobile-popup"
		:class="{'meal-mobile-popup--scrollActive': scrollOn}"
		@scroll="onScroll"
	>

		<div class="meal-mobile-popup__top">
			<div
				class="meal-mobile-popup__back"
				@click="$emit('close')"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="none"
				>
					<path
						fill="#3D4248"
						fill-rule="evenodd"
						d="M19 11H7.1l3.7-4.4a1 1 0 1 0-1.6-1.2l-5 6v.1l-.1.1-.1.4v.4h.1l.1.2 5 6a1 1 0 1 0 1.6-1.2L7 13H19a1 1 0 0 0 0-2Z"
						clip-rule="evenodd"
					/>
				</svg>
			</div>

			<MealListItemSlider
				class="meal-mobile-popup__slider"
				:imgs="imgs"
				:active="activeSlide"
				@change="activeSlide = $event"
			/>
		</div>

		<div class="meal-mobile-popup__bottom">

			<div class="meal-mobile-popup__short-nutrition">
				{{ type }} • {{ weight }} гр
			</div>

			<div class="meal-mobile-popup__title">
				{{ name }}
			</div>

			<div class="meal-mobile-popup__composition-block">
				<div class="meal-mobile-popup__subtitle">Состав продукта:</div>
				<div class="meal-mobile-popup__composition-text">
					{{ composition }}
				</div>
			</div>

			<div class="meal-mobile-popup__nutrition-block">
				<div class="meal-mobile-popup__subtitle">Пищевая ценность блюда:</div>
				<div class="meal-mobile-popup__nutrition-table">
					<div class="meal-mobile-popup__nutrition-table-row">
						<div>Белки</div>
						<div class="meal-mobile-popup__dots"/>
						<div>{{ (nutrition.proteins * .01 * weight).toFixed(0) }} гр</div>
					</div>

					<div class="meal-mobile-popup__nutrition-table-row">
						<div>Жиры</div>
						<div class="meal-mobile-popup__dots"/>
						<div>{{ (nutrition.fats * .01 * weight).toFixed(0) }} гр</div>
					</div>

					<div class="meal-mobile-popup__nutrition-table-row">
						<div>Углеводы</div>
						<div class="meal-mobile-popup__dots"/>
						<div>{{ (nutrition.carbs * .01 * weight).toFixed(0) }} гр</div>
					</div>

					<div class="meal-mobile-popup__nutrition-table-row">
						<div>Энерг. ценность</div>
						<div class="meal-mobile-popup__dots"/>
						<div>{{ (nutrition.calories * .01 * weight).toFixed(0) }} Ккал</div>
					</div>
				</div>
			</div>

			<div class="meal-mobile-popup__rest">
				<span class="meal-mobile-popup__rest-prename">Продавец:</span>

				<div class="meal-mobile-popup__rest-block">
					<span class="meal-mobile-popup__rest-name">{{ rest.name }}</span>

					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						class="meal-mobile-popup__rest-info"
						@click="showTooltip = true"
					>
						<path
							fill-rule="evenodd"
							d="M11 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm0 3a1 1 0 0 1 2 0v5a1 1 0 0 1-2 0v-5Zm1 9a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm0-18a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z"
							clip-rule="evenodd"
						/>
					</svg>

					<Tooltip
						class="meal-mobile-popup__rest-tooltip"
						:info="true"
						:list="false"
						:reverseArrow="true"
						v-if="showTooltip"
						@click.native="showTooltip = false"
					/>
				</div>
			</div>


			<div class="meal-mobile-popup__actions">
				<div class="meal-mobile-popup__prices">

					<div
						class="meal-mobile-popup__old-price"
						v-if="oldPrice"
					>
						Цена:
						<span class="meal-mobile-popup__old-price__crossed">{{ oldPrice }} ₽</span>
						<div class="meal-mobile-popup__short-nutrition">
							{{ type }} • {{ weight }} гр
						</div>

						<div class="meal-mobile-popup__current-price-container">
							<div class="meal-mobile-popup__current-price">{{ price }} ₽</div>
							<div class="meal-mobile-popup__bonus">
								+30
								<img :src="require('@/assets/img/icons/bonus.svg')"/>
							</div>
						</div>

						<div
							class="meal-mobile-popup__in-app-price"
							v-if="inAppPrice"
						>
							В приложении: {{ inAppPrice }}₽
						</div>

					</div>
					<div>
						<UiButton
							@click="changeCartItem(1)"
							v-if="!count"
							:loading="loading"
						>
							В корзину
						</UiButton>
						<Counter
							v-else
							class="meal-mobile-popup__add-to-cart counter"
							:big="isBig"
							:checkCounterBetweenChange="checkCartBeforeAddProduct"
							:value="count"
							@change="changeCartItem"
						/>
					</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
import MealListItemSlider from './MealListItemSlider.vue'
import Tooltip from '../Tooltip.vue';
import Counter from '../../ui/Counter.vue';
import {useChangeCartItem, useYandexMetrika} from "@/composables";
import {mapGetters} from "vuex";
import {UiButton} from "ui-kit";

export default {
	name: 'MealPopup',
	setup()
	{
		const {ecommerceAddToCart, ecommerceRemoveFromCart, ecommerceDetailProduct} = useYandexMetrika();
		const {checkCartBeforeAddProduct, changeCartItem, initProduct, count, loading} = useChangeCartItem();

		return {
			ecommerceAddToCart,
			ecommerceRemoveFromCart,
			ecommerceDetailProduct,
			checkCartBeforeAddProduct,
			changeCartItem,
			initProduct,
			count,
			loading
		};
	},
	components: {
		UiButton,
		MealListItemSlider,
		Tooltip,
		Counter
	},
	props: {
		item: Object,
		id: Number,
		imgs: Array,
		type: String,
		nutrition: Object,
		rating: Number,
		name: String,
		badges: Array,
		composition: String,
		weight: Number,
		discount: Number,
		price: Number,
		oldPrice: Number,
		inAppPrice: Number,
		bonus: Number,
		rest: Object,
	},
	data: () => ({
		activeSlide: 0,
		showTooltip: false,
		scrollOn: null,
	}),
	methods:
		{
			// показывать скролл только при прокрутке
			onScroll($event)
			{
				let target = $event.target.closest('.meal-mobile-popup');
				if (target.scrollHeight - target.scrollTop === target.clientHeight)
				{
					this.scrollOn = false;
				} else this.scrollOn = target.scrollTop > 0;
			}
		},
	computed:
		{
			...mapGetters({
				currentBasket: "getCurrentBasket",
			}),
			isBig()
			{
				return window.innerWidth > 320;
			},
		},
	mounted()
	{
		this.initProduct(this.item);
		// Электронная комерция: Просмотр товара
		if (this.item) this.ecommerceDetailProduct(this.item);
	}
}
</script>

<style lang="scss">
.meal-mobile-popup
{
	display: flex;
	flex-direction: column;
	max-height: 100%;
	overflow-y: auto;

	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar
	{
		width: 3px;
		height: 3px;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}

	&::-webkit-scrollbar-thumb
	{
		background-color: transparent;
	}

	&--scrollActive
	{
		&::-webkit-scrollbar-thumb
		{background-color: #979797;}
	}

	&__top
	{
		height: 304px;
		padding-bottom: 148px;
	}

	&__back
	{
		position: fixed;
		z-index: 2;
		width: 48px;
		height: 48px;
		border-radius: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 32px;
		left: 32px;
		background: rgba(248, 248, 248, 0.8);
	}

	&__bottom
	{
		display: flex;
		flex-direction: column;
		height: calc(100% - 304px);
		width: 100%;
		top: 304px;
		padding: 24px 32px;
		padding-bottom: 48px;
		color: $greyLightText;
	}

	&__slider
	{
		height: 304px;
		width: 100%;
	}

	&__title
	{
		font-weight: bold;
		font-family: $mainFontBoldNew;
		font-size: 24px;
		margin-bottom: 24px;
		color: $textColor;
	}

	&__short-nutrition
	{
		margin-bottom: 12px;
		font-size: 12px;
	}

	&__subtitle
	{
		color: $textColor;
		font-weight: bold;
		font-size: 16px;
		margin-bottom: 8px;
		font-family: $mainFontBoldNew;
	}

	&__composition
	{

		border-right: 4px $greyLightText solid;

		&-block
		{
			margin-bottom: 20px;
		}

		&-text
		{
			font-size: 14px;
		}

	}

	&__nutrition
	{

		&-block
		{
			margin-bottom: 24px;
		}

		&-table
		{

			font-size: 14px;

			&-row
			{
				display: flex;

				*
				{
					flex-shrink: 0;
				}
			}

		}

	}

	&__dots
	{
		width: 100%;
		border-bottom: dotted 1px $greyLightText;
		flex-shrink: 1;
		margin-bottom: 6px;
		margin-left: 6px;
		margin-right: 6px;
	}

	&__rest
	{
		padding-bottom: 16px;
		margin-bottom: 24px;
		border-bottom: 1px solid $greyDelimiter;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-block
		{
			display: flex;
			align-items: center;
			position: relative;
		}

		&-tooltip
		{
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: 20002;
		}

		&-icon
		{
			min-width: 28px;
			margin-right: 8px;
		}

		&-prename
		{
			font-weight: bold;
			font-size: 16px;
			color: $textColor;
			font-family: $mainFontBoldNew;
		}

		&-name
		{

			&-block
			{
				width: 100%;
				justify-content: space-between;
			}

			color: $green;
			font-size: 16px;
			margin-right: 4px;
			text-align: right;
		}

		&-info
		{
			min-width: 24px;
			cursor: pointer;
			fill: #979797;
			border-radius: 24px;
			transition: all .3s ease;

			&:hover
			{
				background: #333;
				fill: #fff;
			}
		}
	}

	&__old-price
	{
		margin-bottom: 10px;
		font-size: 13px;
		line-height: 16px;
		color: $greyLightText;
		font-family: $mainFontMediumNew;
		grid-area: old-price;

		&__crossed
		{
			font-family: $mainFontBoldNew;
			color: $greyLightText;
			text-decoration-line: line-through;
		}
	}

	&__current-price-container
	{
		display: flex;
		grid-area: price;
	}

	&__current-price
	{
		margin-right: 10px;
		font-size: 32px;
		font-family: $mainFontBoldNew;
		letter-spacing: -1px;
		color: $textColor;
	}

	&__current-price
	{
		margin-right: 10px;
		font-size: 34px;
		font-family: $mainFontBoldNew;
		letter-spacing: -1px;
		color: $textColor;
	}

	&__bonus
	{
		padding: 0 7px 0 4px;
		border-radius: 4px;
		background: $green;
		font-family: $mainFontBoldNew;
		font-size: 14px;
		color: #fff;

		display: flex;
		align-items: center;

		img
		{
			margin-left: 3px;
			margin-top: -2px;
		}
	}

	&__in-app-price
	{
		grid-area: in-app-price;
		min-width: 0;
		border-radius: 4px;
		font-size: 11px;
		line-height: 11px;
		color: $green;
		padding: 2px 4px;
		font-family: $mainFontBoldNew;
		background: $lightGreenBackground;
		justify-self: start;
	}

	&__add-to-cart
	{
		width: 100%;
		height: 56px;
		font-size: 14px;
	}

	&__actions
	{
		display: grid;
		align-items: center;
		height: 56px;
		grid-template-columns: 1fr 1fr;

		.btn
		{
			width: 100%;
			height: 56px;
		}
	}

	&__add-to-cart.counter
	{
		align-self: flex-end;
	}

	@media (max-width: 320px)
	{
		&__current-price
		{
			margin-right: 10px;
			font-size: 26px;
			font-family: $mainFontBoldNew;
			letter-spacing: -1px;
			color: $textColor;
		}
	}
	@media not all and (min-resolution: .001dpcm)
	{ @supports (-webkit-appearance:none) and (display:flow-root) and (max-width: 567px)
	{
		.meal-mobile-popup__bottom
		{ padding-bottom: 150px; }
	} }
}
</style>
