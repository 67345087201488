import {notification, useStore} from '@/utils'
import {computed, ref, watchEffect} from "vue";
import {useVkRetargeting, useYandexMetrika, useTopMailCounter} from '@/composables';
import {mobileCheck} from "@/helpers";

/**
 * Хук для добавления и изменения количества продукта в корзине.
 *
 * @example
 * setup()
 * 	{
 * 		const {checkCartBeforeAddProduct, changeCartItem, initProduct, count} = useChangeCartItem();
 *
 * 		return {checkCartBeforeAddProduct, changeCartItem, initProduct, count};
 * 	},
 *
 * <Counter
 * 	:value="count"
 * 	:checkCounterBetweenChange="checkCartBeforeAddProduct"
 * 	change="changeCartItem($event, meal?.cart_id)"
 * />
 *
 * @return {Object} Объект, содержащий следующие функции и значения:
 * - checkCartBeforeAddProduct: функция для проверки корзины перед добавлением продукта
 * - changeCartItem: функция для изменения количества продукта
 * - initProduct: функция для инициализации продукта
 * - count: состояние количества продукта
 */
export const useChangeCartItem = () =>
{
	const store = useStore();
	const {yandexMetrikaGoal, ecommerceAddToCart, ecommerceRemoveFromCart} = useYandexMetrika();
	const {vkRetargetingAddToCart} = useVkRetargeting();
	const {topMailCounterAddToCart} = useTopMailCounter();
	const isDone = computed(() => store.getters.getDone);
	const currentBasket = computed(() => store.getters.getCurrentBasket);
	const user = computed(() => store.getters.getUser);
	const count = ref(0);
	const loading = ref(false);
	const product = ref({});
	const cartMeals = computed(() => store.getters.getCartMeals);

	const checkCartBeforeAddProduct = () =>
	{
		if (isDone.value)
		{
			notification({title: 'Вы завершили сборку коллективной корзины'})

			return false;
		}

		return true;
	}

	const handleBusinessAction = async (newCount) =>
	{
		if (newCount > count.value && product.value)
		{
			const code = product.value?.type?.code;

			if (code) await yandexMetrikaGoal(45209871, `${code}_add`);

			ecommerceAddToCart(product.value);
			vkRetargetingAddToCart(product.value);
			topMailCounterAddToCart();
		} else if (newCount < count.value && product.value)
		{
			ecommerceRemoveFromCart(product.value);
		}
	}

	const changeCartItem = async (newCount, cartId) =>
	{
		if (!checkCartBeforeAddProduct()) return;

		loading.value = true;

		await handleBusinessAction(newCount);

		if (mobileCheck('mobileApp') && newCount > count.value)
		{
			const title = `Товар добавлен <a href="/application/cart">В корзину</a>`;
			notification({title});
		}

		store.commit("setCurrentIdItem", product.value);

		await store.dispatch('addToCart', {id: product.value?.id, count: newCount, cartId: cartId || ''});
		count.value = newCount;

		loading.value = false;
	}

	const initProduct = (initProduct) =>
	{
		product.value = initProduct;
	}

	watchEffect(() =>
	{
		if (product.value.count) return count.value = +product.value?.count;

		if (!cartMeals.value?.length) return count.value = 0;

		const matchingMeal = cartMeals.value.find(meal =>
		{
			const isProductMatch = +meal.product_id === +product.value?.id;
			const isUserMatch = meal.user_id === user.value?.ID;
			const isBasketMatch = !!currentBasket.value?.id;

			return isBasketMatch ? isProductMatch && isUserMatch : isProductMatch;
		})

		count.value = +matchingMeal?.count || 0;
	})

	return {checkCartBeforeAddProduct, changeCartItem, initProduct, count, loading}
}