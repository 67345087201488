export const useTopMailCounter = () =>
{
	//Регистрация нового пользователя
	const topMailCounterCompleteRegistration = () =>
	{
		if (!_tmr) return;

		_tmr.push({type: 'reachGoal', id: 3533368, goal: 'sign_in'});
	}

	//Добавление товара в корзину
	const topMailCounterAddToCart = () =>
	{
		if (!_tmr) return;

		_tmr.push({type: 'reachGoal', id: 3533368, goal: 'add_to_cart'});
	}

	//Совершение покупки (оплата)
	const topMailCounterPurchase = () =>
	{
		if (!_tmr) return;

		_tmr.push({type: 'reachGoal', id: 3533368, goal: 'order_paid'});
	}

	return {
		topMailCounterCompleteRegistration,
		topMailCounterAddToCart,
		topMailCounterPurchase,
	}
}