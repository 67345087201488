<template>
	<div
		class="counter"
		:class="{ 'counter--big': big, 'counter--small': small, 'counter--mini': mini }"
	>

		<button
			class="counter__btn"
			:class="{ 'counter__btn--big': big, 'counter__btn--small': small, 'counter__btn--mini': mini }"
			@click.stop="changeValue('decrement')"
		>
			<img
				class="counter__btn-minus"
				src="@/assets/img/minus.svg"
				alt="Minus"
			>
		</button>

		<div
			class="counter__value"
			:class="{ 'counter__value--big': big, 'counter__value--small': small, 'counter__value--mini': mini }"
		>
			{{ counter }}
		</div>

		<button
			class="counter__btn"
			:class="{ 'counter__btn--big': big, 'counter__btn--small': small, 'counter__btn--mini': mini }"
			@click.stop="changeValue('increment')"
		>
			<img
				class="counter__btn-plus"
				src="@/assets/img/plus.svg"
				alt="Plus"
			>
		</button>

	</div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
	name: 'Counter',
	data()
	{
		return {
			counter: 0,
		}
	},
	props: {
		value: {type: Number, default: 0},
		min: {type: Number, default: 0},
		max: {type: Number, default: 99},
		big: {type: Boolean, default: false},
		small: {type: Boolean, default: false},
		mini: {type: Boolean, default: false},
		canChange: {type: Boolean, default: true},
		checkCounterBetweenChange: {type: Function, default: () => true},
	},
	methods:
		{
			checkCounterBetweenMinAndMax()
			{
				return this.min < this.counter && this.counter < this.max;
			},
			changeValue(type)
			{
				if (!this.canChange || !this.checkCounterBetweenMinAndMax() || !this.checkCounterBetweenChange()) return;

				if (type === 'increment')
					this.counter++;
				else
					this.counter--;

				this.changeCounter();
			},
			changeCounter: debounce(function ()
			{
				this.$emit('change', this.counter);
			}, 500),
		},
	watch:
		{
			value()
			{
				this.counter = this.value || 0;
			},
		},
	mounted()
	{
		this.counter = this.value || 0;
	}
}
</script>

<style lang="scss">
.counter
{
	min-width: 112px;
	height: 36px;
	border-radius: 8px;
	background: $greyBackground;
	border: 2px solid $greyBorder;
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	padding: 0 1px;

	&--big
	{height: 56px;}

	&--small
	{height: 40px;}

	&--mini
	{height: 36px;}
}

.counter__btn
{
	background: #fff;
	width: 30px;
	height: 30px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);

	&--big
	{
		height: 48px;
		width: 48px;
	}

	&--small
	{
		height: 34px;
		width: 34px;
	}

	&--mini
	{
		height: 30px;
		width: 30px;
	}
}

.counter__btn-minus
{
	width: 20px;
	height: 20px;
}

.counter__btn-plus
{
	width: 17px;
	height: 17px;
}

.counter__value
{
	font-size: 16px;
	font-family: $mainFontBoldNew;
	color: $textColor;

	&--big
	{font-size: 18px;}

	&--small
	{font-size: 14px;}

	&--mini
	{font-size: 14px;}
}
</style>