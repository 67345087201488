export const useVkRetargeting = () =>
{
	//Регистрация нового пользователя
	const vkRetargetingCompleteRegistration = () =>
	{
		if (typeof VK === 'undefined') return;

		VK.Goal('complete_registration');
	}

	//Добавление товара в корзину
	const vkRetargetingAddToCart = (product) =>
	{
		if (typeof VK === 'undefined') return;

		VK.Goal('add_to_cart', {
			value: product.price,
		});
	}

	//Начало оформления заказа
	const vkRetargetingInitiateCheckout = () =>
	{
		if (typeof VK === 'undefined') return;

		VK.Goal('initiate_checkout');
	}

	//Совершение покупки (оплата)
	const vkRetargetingPurchase = (products) =>
	{
		if (typeof VK === 'undefined') return;

		const price = products.reduce((acc, product) => acc + product.price * product.quantity, 0);

		VK.Goal('purchase', {
			value: price,
		});
	}

	//Событие для пользователей, досмотревших страницу до конца (низ страницы)
	const vkRetargetingViewContent = () =>
	{
		if (typeof VK === 'undefined') return;

		VK.Goal('view_content');
	}

	//Поиск (ввод) в строке местоположения
	const vkRetargetingFindLocation = () =>
	{
		if (typeof VK === 'undefined') return;

		VK.Goal('find_location');
	}

	return {
		vkRetargetingCompleteRegistration,
		vkRetargetingAddToCart,
		vkRetargetingInitiateCheckout,
		vkRetargetingPurchase,
		vkRetargetingViewContent,
		vkRetargetingFindLocation
	}
}