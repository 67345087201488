<template>
	<div class="meal-list-item-slider">
		<swiper
			v-if="imageObjectFit === 'contain' || !imageObjectFit"
			:options="swiperParams"
			ref="swiper"
			@click="openCurrentMeal()"
		>
			<swiper-slide
				v-for="(item, i) in imgs"
				:key="item + 'itemId'"
				:style="{ backgroundColor: bgrColor }"
			>
				<img
					:src="item"
					@touchmove="changeCurrentIndex(i)"
					alt="item-slide"
					loading="lazy"
					class="swiper-slide__img withoutBg"
					:style="{ objectFit: imageObjectFit }"
					@click="openLunchPopup"
				/>
			</swiper-slide>
			<div
				class="swiper-pagination swiper-pagination-bullets"
				slot="pagination"
			></div>
		</swiper>
		<img
			v-else
			:src="imgs[0]"
			alt="loading"
			loading="lazy"
			class="swiper-slide__img withoutBg"
			:style="{ objectFit: imageObjectFit }"
		/>
		<div
			class="meal-list-item-slider__arrows"
			:class="{ big }"
		>
			<div
				class="meal-list-item-slider__arrow-left"
				:class="{ big }"
			>
				<div
					class="meal-list-item-slider__arrow-fix"
					:class="'meal-list-item-slider__arrow-left-wrapper' + normalItemId"
					ref="arrow_left"
					@click="prevArrow()"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="18"
						fill="none"
						v-if="big"
					>
						<path
							stroke="#3D4248"
							stroke-width="3"
							d="m9.5 1-7 8 7 7.8"
						/>
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						fill="none"
						v-else
					>
						<path
							fill="#3D4248"
							fill-rule="evenodd"
							d="M13.5 17a1 1 0 0 0 .7-1.7L11 12l3.2-3.3a1 1 0 0 0-1.5-1.4l-3.8 4a1 1 0 0 0 0 1.4l4 4c.2.2.4.3.7.3Z"
							clip-rule="evenodd"
						/>
					</svg>
				</div>
			</div>
			<div
				class="meal-list-item-slider__arrow-right"
				:class="{ big }"
			>
				<div
					class="meal-list-item-slider__arrow-fix"
					:class="'meal-list-item-slider__arrow-right-wrapper' + normalItemId"
					ref="arrow_right"
					@click="nextArrow()"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="18"
						fill="none"
						v-if="big"
					>
						<path
							stroke="#3D4248"
							stroke-width="3"
							d="m9.5 1-7 8 7 7.8"
						/>
					</svg>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						fill="none"
						v-else
					>
						<path
							fill="#3D4248"
							fill-rule="evenodd"
							d="M13.5 17a1 1 0 0 0 .7-1.7L11 12l3.2-3.3a1 1 0 0 0-1.5-1.4l-3.8 4a1 1 0 0 0 0 1.4l4 4c.2.2.4.3.7.3Z"
							clip-rule="evenodd"
						/>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import "swiper/css/swiper.min.css";
import "swiper/css/swiper.css";
import {Swiper, SwiperSlide, directive} from "vue-awesome-swiper";

export default {
	name: "MealListItemSlider",
	components:
		{
			Swiper,
			SwiperSlide,
		},
	directives: {swiper: directive,},
	data: () => ({
		currentIndex: 0,
		// Опции слайдера
		swiperParams: {
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
				renderBullet(index, className)
				{
					return `<span class="${className}"></span>`;
				},
			},
			slidesPerView: 1,
			spaceBetween: 16,
			cssMode: true,
			watchSlidesProgress: true,
			watchSlidesVisibility: true,
			mousewheel: {forceToAxis: true,},
			breakpoints:
				{
					576:
						{
							allowTouchMove: false,
							cssMode: false,
						},
				},
		},
	}),
	props:
		{
			itemId: Number,
			imgs: Array,
			imageObjectFit: String,
			bgrColor: String,
			big:
				{
					type: Boolean,
					default: false,
				},
			active:
				{
					type: Number,
					default: 0,
				},
		},
	computed:
		{
			normalItemId()
			{
				return this.itemId;
			},
		},
	methods:
		{
			changeCurrentIndex(idx)
			{
				this.currentIndex = idx;
			},
			nextArrow()
			{
				if (this.currentIndex === this.imgs.length - 1)
				{
					this.currentIndex = 0;
				} else
				{
					this.currentIndex++;
				}
				this.$refs.swiper.$swiper.slideNext();
			},
			prevArrow()
			{
				if (this.currentIndex === 0)
				{
					this.currentIndex = this.imgs.length - 1;
				} else
				{
					this.currentIndex--;
				}
				this.$refs.swiper.$swiper.slidePrev();
			},
			openCurrentMeal()
			{
				this.$emit("toggle");
			},
			openLunchPopup()
			{
				this.$emit("openLunchPopup");
				this.$emit("select");
			},
		},

	watch:
		{
			active(val)
			{
				if (this.currentIndex === val) return;
				this.currentIndex = val;
			},
			currentIndex()
			{
				this.$refs.swiper.$swiper.slideTo(this.currentIndex);
				this.$emit("change", this.currentIndex);
			},
		},
	beforeMount()
	{
		this.currentIndex = this.active;
	},
};
</script>

<style lang="scss">
.meal-list-item-slider
{
	display: flex;
	justify-content: center;
	position: relative;
	height: 100%;

	&__slide
	{
		object-fit: contain;
		width: inherit;
		height: inherit;
		position: absolute;
	}

	.swiper-pagination-bullet
	{
		width: 9px;
		height: 9px;
		border-radius: 100%;
		background: #e5e5e7;
		border: 1px solid hsla(0, 0%, 59.2%, .7);
		margin-right: 8px;
		-webkit-transition: all .3s ease;
		transition: all .3s ease;
		cursor: pointer;
		opacity: 1;
	}

	.swiper-pagination-bullet-active
	{
		background: #40ae49;
		border-color: #40ae49;
	}

	&__trans
	{
		&-enter-active, &-leave-active
		{
			transition: opacity .3s;
		}

		&-enter, &-leave-to
		{
			opacity: 0;
		}
	}

	&__arrows
	{
		position: absolute;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 12px;
		top: calc(50% - 16px);
		opacity: 0;
		transition: opacity .3s ease;
		pointer-events: none;

		&.big
		{
			opacity: 1;
			top: calc(50% - 32px);
		}
	}

	&__arrow-left, &__arrow-right
	{
		pointer-events: all;
		cursor: pointer;
		width: 32px;
		height: 32px;
		border-radius: 100px;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;

		&.big
		{
			width: 60px;
			height: 60px;
			box-shadow: 0px 10px 40px rgba(123, 123, 123, 0.15);
		}
	}

	&__arrow-fix
	{
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
	}

	&__arrow-right
	{
		transform: scaleX(-1);
	}

	&__points
	{
		display: flex;
		position: absolute;
		bottom: 10px;
		z-index: 2;
	}

	&__point
	{
		width: 9px;
		height: 9px;
		border-radius: 100%;
		background: $greyDelimiter;
		border: 1px solid rgba(151, 151, 151, 0.7);
		margin-right: 8px;
		transition: all .3s ease;
		cursor: pointer;

		&.active
		{
			background: $green;
			border-color: $green;
		}
	}

	&:hover
	{
		.meal-list-item-slider__arrows
		{
			z-index: 2;
			opacity: 1;
		}
	}

	.swiper-slide
	{
		display: flex;
		align-items: center;
		justify-content: center;

		&__img
		{
			object-fit: contain;
			height: 95%;
			width: 95%;
		}
	}

}
</style>